@import "../base/variables";
@import "../base/common";

.container {
  margin: auto;
  padding-bottom: $spacing * 2;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
