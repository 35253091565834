@import "../base/variables";
@import "../base/common";

.container {
  min-width: $spacing * 39;
  max-width: $spacing * 59;
  margin: auto;
  background-color: $pageBackgroundColor;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.logoContainer {
  margin: auto;
  padding-top: $spacing * 5;
  display: flex;
  justify-content: center;
  max-height: $spacing * 10;
}

.main {
  margin: $spacing * 5 auto $spacing * 3;
  padding: $spacing * 3;
  background-color: $mainBackgroundColor;
  border-radius: $spacing * 2;
  display: flex;
  flex-direction: column;
  align-items: center;
}
