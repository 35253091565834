@import "../base/variables";

.container {
  margin-top: $spacing * 2;
  padding: $spacing * 2;
  background-color: $blue;
  border: none;
  border-radius: $spacing * 1;
  color: #ffffff;
  font-size: $creditsFontSize;
  font-weight: $bold;
  width: $spacing * 36;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: darken($color: $blue, $amount: 20);
  }
  &:focus {
    outline: 2px dotted $blue;
    outline-offset: 2px;
  }
  &:disabled {
    opacity: 0.6;
  }

  &:hover:disabled {
    background-color: $blue;
  }
}

.product-name-container {
  display: flex;
  align-items: flex-end;
}

.product-name {
  padding-left: $spacing;
}

.credits{

}
