@import "../base/variables";
@import "../base/common";

.container {
  margin: auto;
  padding-bottom: $spacing * 3;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.footer {
  margin: auto;
}
