@import '../base/common';
@import '../base/form';

.textContainer{
  margin: 0 $spacing * 3.5;
  @media only screen and (max-width: 600px) {
    margin: 0 $spacing * 2;
  }
  @media only screen and (max-width: 400px) {
    margin: 0;
  }
}