.form-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}

.form-container {
  label {
    flex-grow: 1;
  }
}

.label-text {
  font-size: $labelFontSize;
  font-weight: $bold;
  line-height: $subTitleLineHeight;
}

.input {
  height: $spacing*4;
  border: none;
  background-color: $pageBackgroundColor;
  padding: 0 $spacing*0.5;
  width: 90%;
  border-radius: $spacing*0.5;
}

.submit {
  height: $spacing*4;
  padding: $spacing*0.5 $spacing*2;
  border-radius: $spacing*0.5;
  background-color: $azure;
  color: $blue;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: darken($color: $azure, $amount: 20);
    color: darken($color: $blue, $amount: 20);
  }

  &:disabled {
    opacity: 0.6;
  }

  &:hover:disabled {
    background-color: $azure;
  }
}

.input-note {
  margin-top: $spacing;
  color: $grey;
  line-height: $subTitleLineHeight;
  transition: max-height 500ms;
  max-height: $spacing*10;

  &.empty {
    max-height: 0;
  }

  & .error {
    color: $red;
  }
}