@import "./variables";

.title {
  font-size: $titleFontSize;
  font-weight: $bold;
}

.sub-title {
  font-size: $subTitleFontSize;
  font-weight: $bold;
  line-height: $subTitleLineHeight;
  margin-bottom: $spacing * 2;
}

.body-text {
  margin-bottom: $spacing * 2;
  color: $grey;
  line-height: $subTitleLineHeight;
}

.fake-link {
  color: $grey;
  text-decoration: underline;
  cursor: pointer;
}

.linkContainer {
  margin-top: $spacing * 2;
}
