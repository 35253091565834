.spinner {
  animation: rotate 2s linear infinite;
  z-index: 2;
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // margin: -9px 0 0 -9px;
  // width: 19px;
  // height: 19px;

  & .path {
    stroke: hsl(210, 70%, 75%);
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
    &.white {
      stroke: #ffffff;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}
